<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <v-card>
          <v-card-title class="elevation-1">
            Slider List (Bottom Sliders)
            <v-spacer></v-spacer>
            <v-row justify="end" class="px-5">
              <v-dialog
                :retain-focus="false"
                v-model="dialog"
                persistent
                max-width="600px"
                :key="dialog"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined color="green" v-bind="attrs" v-on="on">
                    Add
                  </v-btn>
                </template>
                <v-card>
                  <v-form v-model="addslider" ref="addcat">
                    <v-card-title>
                      <span class="headline">Add Slider </span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-text-field
                              v-model="link"
                              label=" Link"
                              :rules="[rules.required]"
                              required
                              outlined
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="12">
                            <ImageComp
                              :singleImage="editingitem.Image"
                              :pageId="editingitem._id"
                              @stepper="winStepper"
                              :height="'600'"
                              :width="'2500'"
                              :heading="'Upload Slider Image for web (2000 * 479)'"
                              :componentType="'sliderImage'"
                            />
                          </v-col>
                             <v-col cols="12" sm="12" md="12">
                            <ImageComp
                              :singleImage="editingitem.Image"
                              :pageId="editingitem._id"
                              @stepper="winStepper"
                              :height="'2'"
                              :width="'3'"
                              :heading="'Upload Slider Image for mobile (300 * 200)'"
                              :componentType="'sliderMobileImage'"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="dialogclose()">
                        Close
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        :disabled="!addslider"
                        @click="add()"
                      >
                        Save
                      </v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-row>
            <v-col>
              <v-btn outlined color="red" href="/trendingSliderDeleted"
                >Deleted List</v-btn
              >
            </v-col>
          </v-card-title>
          <div>
            <v-container grid-list-md>
              <v-layout wrap pt-10 pb-10>
                <v-flex v-for="(item, i) in user" :key="i" md3 pa-4>
                  <v-card style="" tile flat>
                    <div v-viewer style="display: flex; min-height: 200px">
                      <img
                        style="
                          width: 100%;
                          height: 100%;
                          object-fit: contain;
                          cursor: pointer;
                        "
                        :src="baseURL + item.image"
                      />
                    </div>
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="#FF2323"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                    <v-layout wrap justify-center px-2>
                      <v-flex pt-2 xl12 md12 sm12 xs12>
                        <span style="font-size: 13px; color: #000000">
                          {{ item.link }}
                        </span> 
                        <!-- <span style="font-size: 13px; color: #000000">
                          Is Top: {{ item.istop }}
                        </span> -->
                      </v-flex>
                      <v-flex pt-5 px-4 pb-2 xl6 xs6 sm6 md6 lg6 justify-center>
                        <v-dialog v-model="item.delete" max-width="600px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              small
                              class="mr-2"
                              outlined
                              block
                              v-on="on"
                              v-bind="attrs"
                              color="red"
                            >
                              Delete
                            </v-btn>
                          </template>
                          <v-card>
                            <v-card-title
                              >Are you sure you want to delete this
                              Slider?</v-card-title
                            >
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="item.delete = false"
                                >Cancel</v-btn
                              >
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="deleteItem(item)"
                                >OK</v-btn
                              >
                              <v-spacer></v-spacer>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-flex>
                      <v-flex pt-5 px-4 pb-2 xl6 lg6 xs6 sm6 md6 justify-center>
                        <v-btn
                          small
                          class="mr-2"
                          outlined
                          block
                          color="green"
                          @click="editSlider(item)"
                        >
                          Edit
                        </v-btn>
                      </v-flex>
                      <br />
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
          </div>
          <v-dialog v-model="editdialog" max-width="600px">
            <v-card>
              <v-card-title>
                <span class="headline">Edit Slider</span>
              </v-card-title>
              <v-card-text>
                <v-layout>
                  <v-flex xs12 md12 lg12>
                    <v-text-field
                      v-model="editingitem.link"
                      label=" Link"
                      :rules="[rules.required]"
                      required
                      outlined
                    >
                    </v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout wrap>
                  <v-flex xs12 md12 lg12>
                    <ImageComp
                      :singleImage="editingitem.image"
                      :pageId="editingitem._id"
                      @stepper="winStepper"
                      :height="'600'"
                      :width="'2500'"
                      :heading="'Upload Slider Image for Web (2000 * 479)'"
                      :componentType="'sliderImage'"
                    />
                  </v-flex>
                    <v-flex xs12 md12 lg12 pt-4>
                    <ImageComp
                      :singleImage="editingitem.mobileimage"
                      :pageId="editingitem._id"
                      @stepper="winStepper"
                      :height="'2'"
                      :width="'3'"
                      :heading="'Upload Slider Image for Mobile (300 * 200)'"
                      :componentType="'sliderMobileImage'"
                    />
                  </v-flex>
                </v-layout>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="editdialog = false">
                  Close
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="edit(editingitem._id)"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!-- <div class="pt-2">
            <v-pagination
              :length="pages"
              :total-visible="7"
              v-model="currentPage"
            ></v-pagination>
          </div> -->
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
import ImageComp from "@/components/commonComponents/singleImage";
export default {
  components: {
    ImageComp,
  },
  data() {
    return {
      checkbox: true,
      file: null,
      sliderImage: null,
      sliderMobileImage: null,
      showsnackbar: false,
      editingitem: [],
      msg: null,
      pages: 0,
      widthOfCard: "250px",
      link: "",
      appLoading: false,
      currentPage: 1,
      totalData: 0,
      totalRows: 0,
      dialogDelete: false,
      search: "",
      ratings: [],
      dialog: false,
      editdialog: false,
      ServerError: false,
      user: [],
      addslider: false,
      imgId: "",
      images: [],
      rules: {
        required: (value) =>
          /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/.test(
            value
          ) || "Must be link",
        min: (v) => v.length >= 10 || "Min 10 characters",
      },
      Rules: [(value) => !!value || "Required."],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    winStepper(window_data) {
      if (window_data.type == "sliderImage") {
        this.sliderImage = window_data.selectedFiles;
      }
        if (window_data.type == "sliderMobileImage") {
        this.sliderMobileImage = window_data.selectedFiles;
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        url: "/home/slider/getlist",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          // istop:this.checkbox,
          count: this.count,
          page: this.currentPage,
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.user = response.data.data;
          var temp = [];
          // temp = response.data.data;
          for (var i = 0; i < this.user.length; i++) {
            if (this.user[i].istop == false) {
              var ty = this.user[i];
              temp.push(ty);
              this.images.push(ty.image);
            }
          }
          this.user = temp;
          this.pages = response.data.pages;
          this.totalData = response.data.count;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    add() {
      var data = {};
      data["link"] = this.link;
      data["istop"] = false;
      axios({
        url: "/home/slider/add",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;

          if (response.data.status) {
            this.imgId = response.data.id;
            if (this.sliderImage ) {
              this.uploadImage(this.imgId,"web");
            }
            if (this.sliderMobileImage) {
              this.uploadImage(this.imgId,"mobile");
            }
             if (!(this.sliderImage ||this.sliderMobileImage)) {
              this.getData();
              // this.$router.push("/home/trendingSlider");
            }
            this.msg = "Added Sucessfully";
            this.showsnackbar = true;
            this.link = null;
            this.checkbox = null;
            this.imagePreview = null;
            this.file = null;
            this.dialog = false;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    edit() {
      this.appLoading = true;
      var user = {};
      user["link"] = this.editingitem.link;
      user["istop"] = false;
      user["id"] = this.editingitem;
      axios({
        method: "POST",
        url: "/home/slider/edit",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        data: user,
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.editdialog = false;
            if (this.sliderImage ) {
              this.uploadImage(this.editingitem._id,"web");
            }
            if (this.sliderMobileImage) {
              this.uploadImage(this.editingitem._id,"mobile");
            }
             if (!(this.sliderImage ||this.sliderMobileImage)) {
              this.getData();
              this.$router.push("/home/trendingSlider");
            }
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    deleteItem(r) {
      var data = {};
      data["id"] = r._id;
      axios({
        url: "/home/slider/remove",
        method: "POST",
        data: data,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;
            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    dialogclose() {
      this.link = null;
      this.dialog = false;
    },
    editSlider(item) {
      this.editingitem = item;
      this.editdialog = true;
    },
    uploadImage(id,type) {
      let formData = new FormData();
      formData.append("id", id);
      formData.append("type", type);
      if(type=='web')
      formData.append("photo", this.sliderImage);
      if(type=='mobile')
      formData.append("photo", this.sliderMobileImage);

      axios({
        method: "POST",
        url: "/home/slider/uploadimage",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.getData();
            this.showsnackbar = true;
            this.msg = "Uploaded Successfully";
            this.dialog = false;
          } else {
            this.msg = "Can't Update";
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>

<style>
</style>